import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, InputNumber, Form, Typography, Popover } from '$ui/index';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import cn from 'classnames';

import { useCurrentUserLocalQuery } from '$graphql/hooks';
import { Formatter } from '$utils/Formatter';

import { Icon } from '$components/index';
import {
  InputPhone,
  FloatInputLabel,
  Datepicker,
  Timepicker,
  Categories,
  Locations,
  InputNumberButton,
  PromoCode,
} from '$components/Forms';

import { FormArgs } from '../../types';
import { FormProps, TResultPromoCode } from './types';
// import { labelTabs } from './data'; // Temporarily not display. Waiting for a redesign
import LinkUserAgreement from '$components/LinkUserAgreement';
import styles from './Form.module.less';

const FormComponent = ({
  locationSuggestions = [],
  searchLocations,
  onLocationSelect,
  selectedTab,
  // setSelectedTab, // Temporarily not display. Waiting for a redesign
  initialValues,
  onFormComplete,
  boatCategoriesData = [],
  ComponentMap,
  showMap,
}: FormProps) => {
  const { i18n, t } = useTranslation();
  const { Text } = Typography;
  const { data: user } = useCurrentUserLocalQuery();

  const [formOrig] = Form.useForm<FormArgs>();
  const formatterPriceWithCurrency = Formatter.price.bind(
    null,
    localStorage.getItem('currency') || undefined,
    localStorage.getItem('lng') || 'en'
  );
  const [isShowFieldPromoCode, setIsShowFieldPromoCode] = useState(false);
  const [showBudget, setShowBudget] = useState(false);

  const [resultPromoCode, setResultPromoCode] = useState<TResultPromoCode>({ status: 'default' });

  useEffect(() => {
    formOrig.setFieldsValue({
      email: user?.currentUser.email,
    });
  }, [user, formOrig]);

  // TODO 2021 временный костыль для FloatInputLabel
  const locationName = formOrig.getFieldsValue().locationName;
  const isLocationName = locationName ? locationName.length > 0 : false;

  const handleSelectLocations = (value: string, option: OptionData | OptionGroupData) => {
    onLocationSelect(option.key as string, value);
  };

  return (
    <Form
      form={formOrig}
      layout="vertical"
      onFinish={(values) => onFormComplete(values)}
      initialValues={{ ...initialValues }}
      className="ga_request_page_form"
    >
      <Form.Item name="boatCategoryIds" className={styles.categories}>
        <Categories categories={boatCategoriesData} />
      </Form.Item>

      <div className={styles.main}>
        <div className={styles.panelLeft}>
          <FloatInputLabel label={t('frontend.banner.location.label')} isFloated={isLocationName}>
            <Form.Item
              name="locationName"
              extra={t('frontend.request_page.add_locations')}
              className="js-locationName"
              rules={[
                {
                  required: true,
                  message: 'The location is required',
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      const htmlEl = document.querySelector('.js-locationName');
                      if (htmlEl instanceof HTMLElement) {
                        window.scrollTo({
                          top: htmlEl.offsetTop - (64 + 8), // 64px - height header(sticky), 8px - just a padding
                          behavior: 'smooth',
                        });
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Locations
                onChange={searchLocations}
                onSelect={handleSelectLocations}
                items={locationSuggestions}
                config={{ virtual: false }}
                dataCy="location"
              />
            </Form.Item>
          </FloatInputLabel>

          <Form.Item name="flexibleDates" valuePropName="checked">
            <Checkbox data-cy="flexibleDates">{t('frontend.request_page.flexible')}</Checkbox>
          </Form.Item>

          {/*
          // Temporarily not display. Waiting for a redesign
          <div className={cn(styles.flex, styles.tabs)}>
            <Tabs items={labelTabs} selected={selectedTab} changeSelected={setSelectedTab} />
          </div>
          */}

          <div className={styles.flex}>
            <Form.Item
              name="startsEndsAt"
              className={cn(styles.subFlex, 'js-startsEndsAt')}
              style={{ marginBottom: '24px' }}
              rules={[
                {
                  required: true,
                },
                {
                  validator: (_, value) => {
                    if (!value?.startsAt) {
                      const htmlEl = document.querySelector('.js-startsEndsAt');
                      if (htmlEl instanceof HTMLElement) {
                        window.scrollTo({
                          top: htmlEl.offsetTop - (64 + 8), // 64px - height header(sticky), 8px - just a padding
                          behavior: 'smooth',
                        });
                      }
                      return Promise.reject(new Error('The date is required'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Datepicker placeholder="Select dates" dataCy="startsEndsAt" oneDate={selectedTab === 'perHour'} />
            </Form.Item>

            <div className={styles.times}>
              <Form.Item name="startsAtTime" className={styles.time}>
                <Timepicker label="Start time" theme="left" />
              </Form.Item>

              <Form.Item name="endsAtTime" className={styles.time}>
                <Timepicker label="Finish time" theme="right" />
              </Form.Item>
            </div>

            {selectedTab === 'perHour' && (
              <div className={styles.hours}>
                <div className={styles.label}>Hours</div>
                <div className={styles.flex}>
                  <Form.Item name="hourStartsEndsAt" className={styles.subFlex}>
                    <InputNumberButton />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
          <div className={styles.flex}>
            <Form.Item shouldUpdate className={cn(styles.subFlex, styles.guests)}>
              {(form) => (
                <Form.Item name="guests" data-cy="guests">
                  <FloatInputLabel
                    label={t('frontend.request_page.guests')}
                    isFloated={form.getFieldValue('guests') > 0}
                  >
                    <Input
                      type="number"
                      min={0}
                      className={styles.fieldGuests}
                      autoComplete="off"
                      value={form.getFieldValue('guests')}
                      onChange={(e) => {
                        const n = Math.round(Math.abs(Number(e.currentTarget.value)));
                        form.setFieldsValue({ guests: n > 0 ? n : undefined });
                      }}
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>
          </div>

          <div className={styles.flex}>
            <Form.Item shouldUpdate className={styles.subFlex}>
              {(form) => (
                <Form.Item name="email" data-cy="email">
                  <FloatInputLabel label={t('frontend.request_page.email')} isFloated={!!form.getFieldValue('email')}>
                    <Input
                      value={form.getFieldValue('email')}
                      onChange={(e) => form.setFieldsValue({ email: e.currentTarget.value })}
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item shouldUpdate className={cn(styles.subFlex, styles.wrapperPhone)}>
              {(form) => (
                <Form.Item name="phone" data-cy="phone">
                  <FloatInputLabel
                    label={t('frontend.request_page.phone')}
                    labelFloatedStyles={{ marginLeft: '92px' }}
                    isFloated
                  >
                    <InputPhone
                      value={form.getFieldValue('phone')}
                      onChange={(value) => form.setFieldsValue({ phone: value })}
                      language={i18n.language}
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>
          </div>

          <div className={cn(styles.promoCodeOpener, styles.flex)} id="js-promoCode">
            <div className={cn(styles.subFlex)}>
              <Checkbox
                defaultChecked={isShowFieldPromoCode}
                onChange={(e) => setIsShowFieldPromoCode(e.target.checked)}
                data-e2e="promo-code-opener"
              >
                Promo code
              </Checkbox>
            </div>
          </div>

          {isShowFieldPromoCode && (
            <div className={cn(styles.promoCode, styles.flex)}>
              <div className={cn(styles.subFlex)}>
                <Form.Item name="promoCodeOriginal">
                  <PromoCode
                    onResult={(data) => {
                      const el = document.getElementById('js-promoCode');
                      if (el) el.scrollIntoView({ behavior: 'smooth' });

                      setResultPromoCode(data);
                    }}
                  />
                </Form.Item>
              </div>
              <div className={styles.subFlex}>
                <div className={styles.result}>
                  {resultPromoCode.status === 'active' && (
                    <div className={styles.active} data-e2e="promo-code-result">
                      Discount: <strong>{resultPromoCode.discountPercentage}%</strong>
                    </div>
                  )}
                  {resultPromoCode.status === 'none' && (
                    <div className={styles.none} data-e2e="promo-code-result">
                      Promo code not found
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className={cn(styles.priceMax, showBudget && styles.priceMaxOpen)} data-cy="price">
            <Checkbox defaultChecked={showBudget} onChange={(e) => setShowBudget(e.target.checked)}>
              {showBudget ? '-' : '+'} {t('frontend.request_page.budget')}
            </Checkbox>

            {showBudget && (
              <Form.Item shouldUpdate className={styles.wrapperInput}>
                {(form) => (
                  <Form.Item name="priceMax">
                    <FloatInputLabel label="Your price" isFloated>
                      <InputNumber
                        min={0}
                        step={100}
                        formatter={formatterPriceWithCurrency}
                        className={styles.fieldPriceMax}
                        value={form.getFieldValue('priceMax')}
                        onChange={(value) => form.setFieldsValue({ priceMax: value })}
                        data-cy="input"
                      />
                    </FloatInputLabel>
                  </Form.Item>
                )}
              </Form.Item>
            )}
          </div>

          <Form.Item shouldUpdate>
            {(form) => (
              <Form.Item name="comment" data-cy="comment">
                <FloatInputLabel
                  label={t('frontend.request_page.comments')}
                  isFloated={form.getFieldValue('comment')?.length > 0}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    maxLength={200}
                    showCount
                    value={form.getFieldValue('comment')}
                    onChange={(e) => form.setFieldsValue({ comment: e.currentTarget.value })}
                  />
                </FloatInputLabel>
              </Form.Item>
            )}
          </Form.Item>
        </div>

        <div className={styles.panelRight}>
          {showMap && <div className={styles.map}>{ComponentMap}</div>}
          <div className={styles.match}>
            <Popover
              placement="bottomLeft"
              content={
                <div style={{ maxWidth: '400px', width: 'calc(100vw - 80px)' }}>
                  You can claim a refund of the difference in price, if you find a similar offer on another site at a
                  lower price
                </div>
              }
            >
              <Icon name="match" className={styles.icon} /> We price match
            </Popover>
          </div>
          <div className={styles.extraOptions}>
            <div className={styles.title}>Extras</div>
            <Form.Item name="watersports" valuePropName="checked" className={styles.field}>
              <Checkbox data-cy="watersports">{t('frontend.request_page.watersports')}</Checkbox>
            </Form.Item>

            <Form.Item name="transferNeeded" valuePropName="checked" className={styles.field}>
              <Checkbox data-cy="transferNeeded">{t('frontend.request_page.transfer')}</Checkbox>
            </Form.Item>

            <Form.Item name="captain" valuePropName="checked" noStyle>
              <Checkbox data-cy="captain">Captain</Checkbox>
            </Form.Item>
            <div>
              <Text type="secondary">{t('frontend.request_page.captain_text')}</Text>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.panelSubmit}>
        <div className={styles.wrapperSubmit}>
          <Form.Item data-cy="submit">
            <Button
              htmlType="submit"
              type="primary"
              shape="round"
              size="large"
              className="ga_request_page_send_button"
              block
            >
              {t('frontend.banner.get_offers')}
            </Button>
            <LinkUserAgreement clickText={t('frontend.banner.get_offers')} className={styles.linkUserAgreement} />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default FormComponent;

import getboatcom_for_boat_owners_w280 from './assets/w280/getboatcom_for_boat_owners.webp';
import getboatcom_for_boat_owners_w380 from './assets/w380/getboatcom_for_boat_owners.webp';
import why_getboatcom_w280 from './assets/w280/why_getboatcom.webp';
import why_getboatcom_w380 from './assets/w380/why_getboatcom.webp';
import feel_the_luxury_w280 from './assets/w280/feel-the-luxury.webp';
import feel_the_luxury_w380 from './assets/w380/feel-the-luxury.webp';
import international_boat_show_w280 from './assets/w280/international_boat_show.webp';
import international_boat_show_w380 from './assets/w380/international_boat_show.webp';
import boating_vacation_w280 from './assets/w280/boating_vacation.webp';
import boating_vacation_w380 from './assets/w380/boating_vacation.webp';
import most_expensive_yachts_w280 from './assets/w280/most_expensive_yachts.webp';
import most_expensive_yachts_w380 from './assets/w380/most_expensive_yachts.webp';
import boating_in_naples_w280 from './assets/w280/boating_in_naples.webp';
import boating_in_naples_w380 from './assets/w380/boating_in_naples.webp';
import for_yacht_charter_w280 from './assets/w280/for_yacht_charter.webp';
import for_yacht_charter_w380 from './assets/w380/for_yacht_charter.webp';
import side_view_woman_posing_ocean_w280 from './assets/w280/side_view_woman_posing_ocean.webp';
import side_view_woman_posing_ocean_w380 from './assets/w380/side_view_woman_posing_ocean.webp';
import outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation_w280 from './assets/w280/outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation.webp';
import outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation_w380 from './assets/w380/outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation.webp';
import kawaguchiko_lake_japan_w280 from './assets/w280/kawaguchiko_lake_japan.webp';
import kawaguchiko_lake_japan_w380 from './assets/w380/kawaguchiko_lake_japan.webp';
import fishing_boats_zaante_town_marine_zakinthos_greece_w280 from './assets/w280/fishing_boats_zaante_town_marine_zakinthos_greece.webp';
import fishing_boats_zaante_town_marine_zakinthos_greece_w380 from './assets/w380/fishing_boats_zaante_town_marine_zakinthos_greece.webp';
import sailing_boat_middle_calm_sea_by_hills_captured_switzerland_w280 from './assets/w280/sailing_boat_middle_calm_sea_by_hills_captured_switzerland.webp';
import sailing_boat_middle_calm_sea_by_hills_captured_switzerland_w380 from './assets/w380/sailing_boat_middle_calm_sea_by_hills_captured_switzerland.webp';
import sailing_boat_rest_bay_st_john_virgin_islands_w280 from './assets/w280/sailing_boat_rest_bay_st_john_virgin_islands.webp';
import sailing_boat_rest_bay_st_john_virgin_islands_w380 from './assets/w380/sailing_boat_rest_bay_st_john_virgin_islands.webp';
import hvar_island_yachting_w280 from './assets/w280/hvar_island_yachting.webp';
import hvar_island_yachting_w380 from './assets/w380/hvar_island_yachting.webp';
import yachting_in_the_norwegian_fjords_w280 from './assets/w280/yachting_in_the_norwegian_fjords.webp';
import yachting_in_the_norwegian_fjords_w380 from './assets/w380/yachting_in_the_norwegian_fjords.webp';
import how_to_choose_the_perfect_vessel_w280 from './assets/w280/how_to_choose_the_perfect_vessel.webp';
import how_to_choose_the_perfect_vessel_w380 from './assets/w380/how_to_choose_the_perfect_vessel.webp';
import first_sailing_holiday_w280 from './assets/w280/first_sailing_holiday.webp';
import first_sailing_holiday_w380 from './assets/w380/first_sailing_holiday.webp';
import top_mediterranean_sailing_spots_for_your_vacation_w280 from './assets/w280/top_mediterranean_sailing_spots_for_your_vacation.webp';
import top_mediterranean_sailing_spots_for_your_vacation_w380 from './assets/w380/top_mediterranean_sailing_spots_for_your_vacation.webp';
import how_to_choose_the_perfect_yacht_for_your_holiday_at_sea_w280 from './assets/w280/how_to_choose_the_perfect_yacht_for_your_holiday_at_sea.webp';
import how_to_choose_the_perfect_yacht_for_your_holiday_at_sea_w380 from './assets/w380/how_to_choose_the_perfect_yacht_for_your_holiday_at_sea.webp';
import what_to_do_on_a_yacht_w280 from './assets/w280/what_to_do_on_a_yacht.webp';
import what_to_do_on_a_yacht_w380 from './assets/w380/what_to_do_on_a_yacht.webp';

type TArticle = {
  id: number;
  title: string;
  short: string;
  date: string;
  photoW280: string;
  photoW380: string;
  link: string;
};

const articles: Array<TArticle> = [
  {
    id: 1000,
    title: 'Croatia Climate in May: What to Expect for Your Spring Vacation',
    short:
      "Experience the charm of Croatia in May. From perfect weather to UNESCO World Heritage sites, explore Croatia's Dalmatian...",
    date: '14.02.2025',
    photoW280: side_view_woman_posing_ocean_w280,
    photoW380: side_view_woman_posing_ocean_w380,
    link: 'https://blog.getboat.com/travel-inspiration/croatia-climate-in-may/',
  },
  {
    id: 1010,
    title: 'Croatia in May: Exploring the Perfect Temperature for a Delightful Spring Getaway',
    short:
      'Uncover the ideal temperature of Croatia in May, perfect for exploring the Adriatic coast, enjoying beach activities, and...',
    date: '14.02.2025',
    photoW280: outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation_w280,
    photoW380: outdoor_summer_fashion_portrait_stylish_woman_posing_near_palm_trees_enjoys_exotic_vacation_w380,
    link: 'https://blog.getboat.com/travel-tips-advice/temperature-of-croatia-in-may/',
  },
  {
    id: 1020,
    title: 'Experience Fall in Croatia: A Beautiful Time to Explore National Parks and the Dalmatian Coast',
    short:
      'Fall in Croatia offers cooler temperatures, fewer crowds, and breathtaking natural beauty. From the stunning Plitvice Lakes...',
    date: '14.02.2025',
    photoW280: kawaguchiko_lake_japan_w280,
    photoW380: kawaguchiko_lake_japan_w380,
    link: 'https://blog.getboat.com/travel-tips-advice/fall-in-croatia/',
  },
  {
    id: 1030,
    title: 'Adriatic Cruising: Discover the Best Adriatic Sea Cruises & Destinations',
    short:
      'Embark on an unforgettable Adriatic cruising adventure. Discover Adriatic Sea cruises to Dubrovnik, Split, Venice, and...',
    date: '14.02.2025',
    photoW280: fishing_boats_zaante_town_marine_zakinthos_greece_w280,
    photoW380: fishing_boats_zaante_town_marine_zakinthos_greece_w380,
    link: 'https://blog.getboat.com/travel-tips-advice/adriatic-cruising/',
  },
  {
    id: 1040,
    title: 'The Ultimate Guide to Croatian Sailing: Best Season, Destinations, and Tips',
    short:
      'Discover the best season in Croatia, must-visit destinations, and expert tips for an unforgettable sailing trip on the...',
    date: '14.02.2025',
    photoW280: sailing_boat_middle_calm_sea_by_hills_captured_switzerland_w280,
    photoW380: sailing_boat_middle_calm_sea_by_hills_captured_switzerland_w380,
    link: 'https://blog.getboat.com/travel-inspiration/croatian-sailing/',
  },
  {
    id: 1050,
    title: 'Exploring the Windward Caribbean Islands: A Guide to the Best Destinations in the Lesser Antilles',
    short:
      'Visit the Windward Caribbean Islands for an unforgettable Caribbean experience filled with natural beauty and vibrant...',
    date: '14.02.2025',
    photoW280: sailing_boat_rest_bay_st_john_virgin_islands_w280,
    photoW380: sailing_boat_rest_bay_st_john_virgin_islands_w380,
    link: 'https://blog.getboat.com/travel-inspiration/windward_caribbean_islands/',
  },
  {
    id: 1060,
    title: 'Yachting Adventure Around Hvar Island: A Slice of Paradise in Croatia',
    short:
      'Hvar Island, a dazzling jewel in Croatia’s Adriatic Sea, is famed for its stunning beaches, ancient history, and...',
    date: '14.02.2025',
    photoW280: hvar_island_yachting_w280,
    photoW380: hvar_island_yachting_w380,
    link: 'https://blog.getboat.com/travel-inspiration/hvar-island-yachting-paradise-in-croatia/',
  },
  {
    id: 1070,
    title: 'Discover the Magic of Yachting in the Norwegian Fjords',
    short:
      'Yachting in Norway offers a unique perspective on the country’s dramatic landscapes. Imagine cruising through...',
    date: '14.02.2025',
    photoW280: yachting_in_the_norwegian_fjords_w280,
    photoW380: yachting_in_the_norwegian_fjords_w380,
    link: 'https://blog.getboat.com/travel-inspiration/yachting-in-the-norwegian-fjords/',
  },
  {
    id: 1080,
    title: 'How to Choose the Perfect Vessel for Your Boating Adventures',
    short:
      'Boating is one of the most versatile hobbies, offering relaxation, adventure, and functionality. Whether you’re...',
    date: '14.02.2025',
    photoW280: how_to_choose_the_perfect_vessel_w280,
    photoW380: how_to_choose_the_perfect_vessel_w380,
    link: 'https://blog.getboat.com/travel-tips-advice/how-to-choose-the-perfect-vessel/',
  },
  {
    id: 1090,
    title: 'First Sailing Holiday: Everything You Need to Know About Sailing for Beginners',
    short:
      'Embarking on Your First Time Sailing Adventure For many, the dream of gliding across the water, guided by the wind...',
    date: '14.02.2025',
    photoW280: first_sailing_holiday_w280,
    photoW380: first_sailing_holiday_w380,
    link: 'https://blog.getboat.com/travel-tips-advice/first-sailing-holiday-for-beginners/',
  },
  {
    id: 1100,
    title: 'Navigating Paradise: Top Mediterranean Sailing Spots for Your Vacation',
    short:
      'The Mediterranean stands as a world-famous sailing destination, offering breathtaking coastlines, crystal-clear...',
    date: '14.02.2025',
    photoW280: top_mediterranean_sailing_spots_for_your_vacation_w280,
    photoW380: top_mediterranean_sailing_spots_for_your_vacation_w380,
    link: 'https://blog.getboat.com/travel-inspiration/navigating-paradise-top-mediterranean-sailing-spots-for-your-vacation/',
  },
  {
    id: 1110,
    title: 'How to Choose the Perfect Yacht for Holiday at Sea',
    short:
      'With so many options available, it’s essential to understand the different types of yachts and how to choose the best...',
    date: '14.02.2025',
    photoW280: how_to_choose_the_perfect_yacht_for_your_holiday_at_sea_w280,
    photoW380: how_to_choose_the_perfect_yacht_for_your_holiday_at_sea_w380,
    link: 'https://blog.getboat.com/trends-in-yachting/how-to-choose-the-perfect-yacht-for-holiday/',
  },
  {
    id: 1120,
    title: 'What to Do on a Yacht: Explore the Ultimate Yachting Experience',
    short:
      'Whether you’re new to yacht charters or a seasoned yachting enthusiast, knowing what to do on a yacht can make...',
    date: '14.02.2025',
    photoW280: what_to_do_on_a_yacht_w280,
    photoW380: what_to_do_on_a_yacht_w380,
    link: 'https://blog.getboat.com/transfer-tips-advice/what-to-do-on-a-yacht/',
  },
  {
    id: 1130,
    title: 'GetBoat.com for Boat Owners',
    short:
      'GetBoat.com is a marketplace that not only assists in renting yachts worldwide but also provides exceptional business...',
    date: '26.11.2024',
    photoW280: getboatcom_for_boat_owners_w280,
    photoW380: getboatcom_for_boat_owners_w380,
    link: 'https://newsgetboat.com/getboat-com-for-boat-owners/',
  },
  {
    id: 1140,
    title: 'Why GetBoat.com?',
    short:
      'For 6 years GetBoat.com, a unique tender-based marketplace for yacht charters, has been helping people to find...',
    date: '11.11.2024',
    photoW280: why_getboatcom_w280,
    photoW380: why_getboatcom_w380,
    link: 'https://newsgetboat.com/why-getboat-com/',
  },
  {
    id: 1150,
    title: 'Feel the Luxury of Saint-Tropez and Mediterranean with the Aurelia Superyacht!',
    short:
      'Saint-Tropez is considered one of the favorite destinations for yachting enthusiasts. This is not surprising, considering...',
    date: '08.06.2023',
    photoW280: feel_the_luxury_w280,
    photoW380: feel_the_luxury_w380,
    link: 'https://newsgetboat.com/feel-the-luxury/',
  },
  {
    id: 1160,
    title: 'Set Sail for Adventure: Join the Athens International Boat Show 2023!',
    short:
      'The end of 2023 will please all boating enthusiasts with a rather prestigious event known as the Athens International Boat Show...',
    date: '18.05.2023',
    photoW280: international_boat_show_w280,
    photoW380: international_boat_show_w380,
    link: 'https://newsgetboat.com/international_boat_show/',
  },
  {
    id: 1170,
    title: 'How to pick a perfect vessel for your boating vacation',
    short: 'Boating is a great way to spend time with your family and friends, but it’s also an expensive hobby...',
    date: '24.04.2023 ',
    photoW280: boating_vacation_w280,
    photoW380: boating_vacation_w380,
    link: 'https://newsgetboat.com/boating_vacation/',
  },
  {
    id: 1180,
    title: 'The World’s Most Expensive Yachts',
    short: 'Each of us at least once in our lives has dreamed of relaxing on board a luxury superyacht...',
    date: '06.04.2023',
    photoW280: most_expensive_yachts_w280,
    photoW380: most_expensive_yachts_w380,
    link: 'https://newsgetboat.com/most_expensive_yachts/',
  },
  {
    id: 1190,
    title: 'Boating in Naples: best places to rent a boat in Naples, Florida',
    short:
      'The Florida Gulf Coast resort of Naples is rather well-known. Naples and the surrounding area provide a lot to see...',
    date: '30.03.2023',
    photoW280: boating_in_naples_w280,
    photoW380: boating_in_naples_w380,
    link: 'https://newsgetboat.com/florida/',
  },
  {
    id: 1200,
    title: 'Unwritten Rules for Yacht Charter',
    short:
      'When you’re on a yacht charter, there are some unspoken rules that you need to follow to make the experience a positive one...',
    date: '27.03.2023',
    photoW280: for_yacht_charter_w280,
    photoW380: for_yacht_charter_w380,
    link: 'https://newsgetboat.com/for_yacht_charter/',
  },
];

export default articles;
